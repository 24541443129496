import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const MixedNoteValueSorter = ({ data }) => {
  return (
    <Layout>
      <SEO title="MIB-9 Mixed Note Value Counter And Sorter" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              MIB-9 Mixed Note Value Counter And Sorter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Modern Design, Friendly Interface
            </h3>
            <p className="mb-4">
              A full-featured unit with a sleek modern design and user-friendly
              interface.
            </p>
            <p className="mb-4">
              The MIB9 allows operators to perform daily cash processing jobs
              without interruption using premium counterfeit detect (IR, UV, and
              MG) and CIS image bar, for enhanced note recognition and
              authentication. Suspect and unrecognised banknotes are
              automatically diverted to the reject pocket whilst assuring
              continuous operation. The unit comes with 20 currencies as
              standard (upgrade to 50 available) and is ideal for currency
              exchanges, banks and tourism operators.
            </p>
            <p className="mb-4">
              The MIB9 has two pockets, a stacker and reject pocket with a
              variety of useful functions; which can keep up with growing
              customer demands from all over the world.
            </p>

            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specifications</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <td>Counting Speed (per minute)</td>
                        <td>700 / 1000 / 1200 & 1500 notes</td>
                      </tr>
                      <td>Hopper Capacity</td>
                      <td>Up to approx. 500 notes</td>
                      <tr>
                        <td>Stacker Capacity</td>
                        <td>Up to approx. 200 notes</td>
                      </tr>
                      <tr>
                        <td>Reject Capacity</td>
                        <td>70 notes</td>
                      </tr>
                      <tr>
                        <td>Dimensions</td>
                        <td>280mm (W) x 269mm (D) x 261mm (H)</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>8kg</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/3">
          <div className="w-full max-w-sm px-4 mx-auto">
            <Image fluid={data.MixedNoteValueSorter.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

MixedNoteValueSorter.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query MixedNoteValueSorterQuery {
    MixedNoteValueSorter: file(relativePath: { eq: "products/mib9/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default MixedNoteValueSorter;
